import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('@/views/dashboard/Index.vue'),
      },
      {
        path: '/basic-config',
        name: 'BasicConfig',
        redirect: '/basic-config/area',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/basic-config/area',
            name: 'Area',
            component: () => import('@/views/basic-config/Area.vue'),
          },
          {
            path: '/basic-config/department',
            name: 'Department',
            component: () => import('@/views/basic-config/Department.vue'),
          },
          {
            path: '/basic-config/work-type',
            name: 'WorkType',
            component: () => import('@/views/basic-config/WorkType.vue'),
          },
          {
            path: '/basic-config/work-title',
            name: 'WorkTitle',
            component: () => import('@/views/basic-config/WorkTitle.vue'),
          },
          {
            path: '/basic-config/work-team',
            name: 'WorkTeam',
            component: () => import('@/views/basic-config/WorkTeam.vue'),
          },
          {
            path: '/basic-config/work-class',
            name: 'WorkClass',
            component: () => import('@/views/basic-config/WorkClass.vue'),
          },
          {
            path: '/basic-config/employee',
            name: 'Employee',
            component: () => import('@/views/basic-config/Employee.vue'),
          },
          {
            path: '/basic-config/area-bind',
            name: 'AreaBind',
            component: () => import('@/views/basic-config/AreaBind.vue'),
          },
          {
            path: '/basic-config/work-path',
            name: 'WorkPath',
            component: () => import('@/views/basic-config/WorkPath.vue'),
          },
          {
            path: '/basic-config/over-config',
            name: 'OverConfig',
            component: () => import('@/views/basic-config/OverConfig.vue'),
          },
          {
            path: '/basic-config/schedule-leader',
            name: 'ScheduleLeader',
            component: () => import('@/views/basic-config/ScheduleLeader.vue'),
          },
        ],
      },
      {
        path: '/vehicle-config',
        name: 'VehicleConfig',
        redirect: '/vehicle-config/vehicle',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/vehicle-config/vehicle',
            name: 'Vehicle',
            component: () => import('@/views/vehicle-config/Vehicle.vue'),
          },
          {
            path: '/vehicle-config/types',
            name: 'VehicleType',
            component: () => import('@/views/vehicle-config/Type.vue'),
          },
        ],
      },
      {
        path: '/realtime-query',
        name: 'RealtimeQuery',
        redirect: '/realtime-query/employee-location',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/realtime-query/vehicle-location',
            name: 'RealtimeVehicleLocation',
            component: () => import('@/views/realtime-query/VehicleLocation.vue'),
          },
          {
            path: '/realtime-query/employee-location',
            name: 'RealtimeEmployeeLocation',
            component: () => import('@/views/realtime-query/EmployeeLocation.vue'),
          },
          {
            path: '/realtime-query/under-mine-cards',
            name: 'RealtimeUnderMineCards',
            component: () => import('@/views/realtime-query/UnderMineCards.vue'),
          },
          {
            path: '/realtime-query/area-card-stats',
            name: 'RealtimeAreaCardStats',
            component: () => import('@/views/realtime-query/AreaCardStats.vue'),
          },
          {
            path: '/realtime-query/alert',
            name: 'RealtimeAlert',
            component: () => import('@/views/realtime-query/Alert.vue'),
          },
          {
            path: '/realtime-query/event',
            name: 'RealtimeEvent',
            component: () => import('@/views/realtime-query/Event.vue'),
          },
          {
            path: '/realtime-query/band',
            name: 'Band',
            component: () => import('@/views/realtime-query/Band.vue'),
          },
          {
            path: '/realtime-query/alarm/overtime',
            name: 'RealtimeOvertime',
            component: () => import('@/views/realtime-query/alarm/Overtime.vue'),
          },
          {
            path: '/realtime-query/alarm/unknown-cards',
            name: 'RealtimeUnknownCards',
            component: () => import('@/views/realtime-query/alarm/UnknownCard.vue'),
          },
          {
            path: '/realtime-query/alarm/card-over-limit',
            name: 'RealtimeCardOverLimit',
            component: () => import('@/views/realtime-query/alarm/CardOverLimit.vue'),
          },
          {
            path: '/realtime-query/alarm/area-card-over-limit',
            name: 'RealtimeAreaCardOverLimit',
            component: () => import('@/views/realtime-query/alarm/AreaCardOverLimit.vue'),
          },
          {
            path: '/realtime-query/alarm/area-restrict',
            name: 'RealtimeAreaRestrict',
            component: () => import('@/views/realtime-query/alarm/AreaRestrict.vue'),
          },
          {
            path: '/realtime-query/alarm/area-dangerous',
            name: 'RealtimeAreaDangerous',
            component: () => import('@/views/realtime-query/alarm/AreaDangerous.vue'),
          },
          {
            path: '/realtime-query/alarm/low-power-card',
            name: 'RealtimeLowPowerCard',
            component: () => import('@/views/realtime-query/alarm/LowPowerCard.vue'),
          },
          {
            path: '/realtime-query/alarm/card-alert',
            name: 'RealtimeCardAlert',
            component: () => import('@/views/realtime-query/alarm/CardAlert.vue'),
          },
          {
            path: '/realtime-query/alarm/work-path',
            name: 'RealtimeWorkPath',
            component: () => import('@/views/realtime-query/alarm/WorkPath.vue'),
          },
          {
            path: '/realtime-query/alarm/devices',
            name: 'RealtimeDevice',
            component: () => import('@/views/realtime-query/alarm/Device.vue'),
          },
          {
            path: '/realtime-query/alarm/fences',
            name: 'RealtimeFence',
            component: () => import('@/views/realtime-query/alarm/Fence.vue'),
          },
          {
            path: '/realtime-query/alarm/gate',
            name: 'RealtimeGate',
            component: () => import('@/views/realtime-query/alarm/Gate.vue'),
          },
          {
            path: '/realtime-query/alarm/stay-overtime',
            name: 'RealtimeStayOvertime',
            component: () => import('@/views/realtime-query/alarm/StayOvertime.vue'),
          },
          {
            path: '/realtime-query/alarm/area-early-warn-limit',
            name: 'AreaEarlyWarnLimit',
            component: () => import('@/views/realtime-query/alarm/AreaEarlyWarnLimit.vue'),
          },
          {
            path: '/realtime-query/alarm/early-warn-card-limit',
            name: 'EarlyWarnCardLimit',
            component: () => import('@/views/realtime-query/alarm/EarlyWarnCardLimit.vue'),
          },
        ],
      },
      {
        path: '/history-query',
        name: 'HistoryQuery',
        redirect: '/history-query/card-track',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/history-query/card-track',
            name: 'HistoryCardTrack',
            component: () => import('@/views/history-query/CardTrack.vue'),
          },
          {
            path: '/history-query/vehicle-card-track',
            name: 'HistoryVehicleCardTrack',
            component: () => import('@/views/history-query/VehicleCardTrack.vue'),
          },
          {
            path: '/history-query/band',
            name: 'HistoryBand',
            component: () => import('@/views/history-query/Band.vue'),
          },
          {
            path: '/history-query/alarm/work-overtime',
            name: 'HistoryWorkOvertime',
            component: () => import('@/views/history-query/alarm/WorkOvertime.vue'),
          },
          {
            path: '/history-query/alarm/card-over-limit',
            name: 'HistoryCardOverLimit',
            component: () => import('@/views/history-query/alarm/CardOverLimit.vue'),
          },
          {
            path: '/history-query/alarm/area-card-over-limit',
            name: 'HistoryAreaCardOverLimit',
            component: () => import('@/views/history-query/alarm/AreaCardOverLimit.vue'),
          },
          {
            path: '/history-query/alarm/card-low-power',
            name: 'HistoryCardLowPower',
            component: () => import('@/views/history-query/alarm/CardLowPower.vue'),
          },
          {
            path: '/history-query/alarm/work-path',
            name: 'HistoryWorkPath',
            component: () => import('@/views/history-query/alarm/WorkPath.vue'),
          },
          {
            path: '/history-query/alarm/card-alert',
            name: 'HistoryCardAlert',
            component: () => import('@/views/history-query/alarm/CardAlert.vue'),
          },
          {
            path: '/history-query/alarm/devices',
            name: 'HistoryDevice',
            component: () => import('@/views/history-query/alarm/Device.vue'),
          },
          {
            path: '/history-query/alarm/fences',
            name: 'HistoryFence',
            component: () => import('@/views/history-query/alarm/Fence.vue'),
          },
          {
            path: '/history-query/alarm/stay-overtime',
            name: 'HistoryStayOvertime',
            component: () => import('@/views/history-query/alarm/StayOvertime.vue'),
          },
          {
            path: '/history-query/alarm/area-early-warn-limit',
            name: 'HistoryAreaEarlyWarnLimit',
            component: () => import('@/views/history-query/alarm/AreaEarlyWarnLimit.vue'),
          },
          {
            path: '/history-query/alarm/early-warn-card-limit',
            name: 'HistoryEarlyWarnCardLimit',
            component: () => import('@/views/history-query/alarm/EarlyWarnCardLimit.vue'),
          },
          {
            path: '/history-query/card-binding',
            name: 'HistoryCardBinding',
            component: () => import('@/views/history-query/CardBinding.vue'),
          },
          {
            path: '/history-query/card-in-out-stats',
            name: 'HistoryCardInOutStats',
            component: () => import('@/views/history-query/CardInOutStats.vue'),
          },
          {
            path: '/history-query/card-position',
            name: 'HistoryCardPosition',
            component: () => import('@/views/history-query/CardPosition.vue'),
          },
          {
            path: '/history-query/alarm/gate',
            name: 'HistoryGate',
            component: () => import('@/views/history-query/alarm/Gate.vue'),
          },
          {
            path: '/history-query/operate-log',
            name: 'HistoryOperateLog',
            component: () => import('@/views/history-query/OperateLog.vue'),
          },
          {
            path: '/history-query/card-stats',
            name: 'CardStats',
            component: () => import('@/views/history-query/CardStats.vue'),
          },
          {
            path: '/history-query/area-card-stats',
            name: 'AreaCardStats',
            component: () => import('@/views/history-query/AreaCardStats.vue'),
          },
          {
            path: '/history-query/pass-by-reader',
            name: 'CardPassBy',
            component: () => import('@/views/history-query/PassByReader.vue'),
          },
          {
            path: '/history-query/card-daily-stats',
            name: 'CardDailyStats',
            component: () => import('@/views/history-query/CardDailyStats.vue'),
          },
        ],
      },
      {
        path: '/alarm-config',
        name: 'AlarmConfig',
        redirect: '/alarm-config/station',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/alarm-config/station',
            name: 'StationAlarm',
            component: () => import('@/views/alarm-config/Station.vue'),
          },
          {
            path: '/alarm-config/reader',
            name: 'ReaderAlarm',
            component: () => import('@/views/alarm-config/Reader.vue'),
          },
          {
            path: '/alarm-config/tag',
            name: 'TagAlarm',
            component: () => import('@/views/alarm-config/Tag.vue'),
          },
          {
            path: '/alarm-config/area',
            name: 'AreaAlarm',
            component: () => import('@/views/alarm-config/Area.vue'),
          },
          {
            path: '/alarm-config/one-key',
            name: 'OneKeyAlarm',
            component: () => import('@/views/alarm-config/OneKey.vue'),
          },
        ],
      },
      {
        path: '/device-config',
        name: 'DeviceConfig',
        redirect: '/device-config/station',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/device-config/station',
            name: 'Station',
            component: () => import('@/views/device-config/Station.vue'),
          },
          {
            path: '/device-config/reader',
            name: 'Reader',
            component: () => import('@/views/device-config/Reader.vue'),
          },
          {
            path: '/device-config/tag',
            name: 'Tag',
            component: () => import('@/views/device-config/Tag.vue'),
          },
          {
            path: '/device-config/vehicle-tag',
            name: 'VehicleTag',
            component: () => import('@/views/device-config/VehicleTag.vue'),
          },
          {
            path: '/device-config/power',
            name: 'Power',
            component: () => import('@/views/device-config/Power.vue'),
          },
          {
            path: '/device-config/led',
            name: 'LED',
            component: () => import('@/views/device-config/LED.vue'),
          },
          {
            path: '/device-config/gate',
            name: 'Gate',
            component: () => import('@/views/device-config/Gate.vue'),
          },
          {
            path: '/device-config/tts',
            name: 'TTS',
            component: () => import('@/views/device-config/TTS.vue'),
          },
        ],
      },
      {
        path: '/attendance-config',
        name: 'AttendanceConfig',
        redirect: '/attendance-config/schedule',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/attendance-config/schedule',
            name: 'Schedule',
            component: () => import('@/views/attendance/Scheduler.vue'),
          },
          {
            path: '/attendance-config/daily',
            name: 'DailyAttendance',
            component: () => import('@/views/attendance/Daily.vue'),
          },
          {
            path: '/attendance-config/month',
            name: 'MonthAttendance',
            component: () => import('@/views/attendance/Month.vue'),
          },
          {
            path: '/attendance-config/abnormal',
            name: 'Abnormal',
            component: () => import('@/views/attendance/Abnormal.vue'),
          },
          {
            path: '/attendance-config/abnormal-attendance',
            name: 'AbnormalAttendance',
            component: () => import('@/views/attendance/AbnormalAttendance.vue'),
          },
          {
            path: '/attendance-config/stats',
            name: 'AttendanceStats',
            component: () => import('@/views/attendance/Stats.vue'),
          },
          {
            path: '/attendance-config/department',
            name: 'DepartmentAttendance',
            component: () => import('@/views/attendance/Department.vue'),
          },
        ],
      },
      {
        path: '/map',
        name: 'Map',
        redirect: '/map/map',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/map/map',
            name: 'MapView',
            component: () => import('@/views/map/Map.vue'),
          },
          {
            path: '/map/import',
            name: 'Import',
            component: () => import('@/views/map/Import.vue'),
          },
          {
            path: '/map/config',
            name: 'Config',
            component: () => import('@/views/map/Config.vue'),
          },
        ],
      },
      {
        path: '/system-config',
        name: 'SystemConfig',
        redirect: '/system-config/personal',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/system-config/diagnosis',
            name: 'diagnosis',
            component: () => import('@/views/system-config/Diagnosis.vue'),
          },
          {
            path: '/system-config/personal',
            name: 'Personal',
            component: () => import('@/views/system-config/Personal.vue'),
          },
          {
            path: '/system-config/user',
            name: 'User',
            component: () => import('@/views/system-config/User.vue'),
          },
          {
            path: '/system-config/group',
            name: 'Group',
            component: () => import('@/views/system-config/Group.vue'),
          },
          {
            path: '/system-config/mine',
            name: 'Mine',
            component: () => import('@/views/system-config/Mine.vue'),
          },
          {
            path: '/system-config/system',
            name: 'System',
            component: () => import('@/views/system-config/System.vue'),
          },
          {
            path: '/system-config/about',
            name: 'About',
            component: () => import('@/views/system-config/About.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
    ],
  },
  {
    path: '/hide',
    redirect: '/hide/track/future',
    name: 'Hide',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '/hide/clearMap',
        name: 'ClearMap',
        component: () => import('@/views/hide/ClearMap.vue'),
      },
      {
        path: '/hide/ma/db14/overtime',
        name: 'Overtime',
        component: () => import('@/views/hide/ma/db14/Overtime.vue'),
      },
      {
        path: '/hide/gate',
        name: 'GateHide',
        component: () => import('@/views/hide/Gate.vue'),
      },
      {
        path: '/hide/track/future',
        name: 'TrackFuture',
        component: () => import('@/views/hide/track/Future.vue'),
      },
      {
        path: '/hide/track/history',
        name: 'TrackHistory',
        component: () => import('@/views/hide/track/History.vue'),
      },
      {
        path: '/hide/device-status',
        name: 'DeviceStatus',
        component: () => import('@/views/hide/DeviceStatus.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.afterEach((to) => {
  const visible = to.name !== 'Home'
  store.commit({ type: 'updateSidebarVisible', value: visible })
})

export default router
