<template>
  <div class="top" v-if="!sidebarVisible">
    <div class="left">
      <div style="background: transparent; margin-left: 10px">
        <div class="pt-0 pb-1">
          <div class="timeColor">
            <img src="@/assets/images/dashboard/logo.png" alt="logo" width="120" height="40" />
            {{ timeNow }}
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="title">{{ this.mineName + systemName }}</div>
    </div>
    <div class="right">
      <div style="display: flex; margin: 5px 0">
        <span class="rightBtn" @click="goHome('/basic-config')">系统管理</span>
        <span v-if="linkConfig.url" class="rightBtn" @click="goHome(linkConfig.url)">{{
          linkConfig.name.substring(0, 5)
        }}</span>
      </div>
      <div style="display: flex; margin-right: 10px">
        <AlertNotify />
        <AppHeaderDropdownAccnt />
      </div>
    </div>
  </div>

  <CHeader class="bg-header" v-else>
    <CContainer fluid>
      <CHeaderNav class="d-none d-md-flex me-auto fs-5">
        <CNavItem>
          <CNavLink href="/">
            <span class="text-header"> 首页 </span>
          </CNavLink>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav>
        <AlertNotify />
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
import calendar from 'js-calendar-converter'

import { AlertNotify } from 'ui-common'

import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import config from '@/config'
import store from '@/store'
import { dateTimeFormat } from '@/common/utils'
import { httpGet } from '@/common/fetch'
import { mapState } from 'vuex'

const weekDayMap = ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']

export default {
  name: 'AppHeader',
  components: {
    AppHeaderDropdownAccnt,
    AlertNotify,
  },
  data() {
    const linkConfig = window.config.linkConfig
    return {
      systemName: config.systemName,
      utcMillis: 0,
      updateCnt: 0,
      timeNow: '',
      timerId: null,
      period: 600,
      linkConfig,
    }
  },
  computed: {
    sidebarVisible: () => store.state.sidebarVisible,
    ...mapState(['mineName']),
  },
  methods: {
    goHome(path) {
      window.location.href = path
    },
    updateTime() {
      if (this.updateCnt % this.period === 0) {
        this.updateCnt = 0
        const start = Date.now()
        httpGet(config.baseURL + '/time')
          .then((response) => response.json())
          .then((time) => {
            const rtt = (Date.now() - start) / 2
            this.utcMillis = time + rtt
            this.period = 600
          })
          .catch(() => (this.period = 30))
      }

      this.updateCnt += 1
      this.utcMillis += 1000
      const now = new Date(this.utcMillis)
      const nowFormat = dateTimeFormat(now)
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const calendarCnJson = calendar.solar2lunar(year, month, day)
      const monthCn = calendarCnJson.IMonthCn
      const dayCn = calendarCnJson.IDayCn
      const calendarCn = monthCn + dayCn
      this.timeNow = nowFormat + ' ' + weekDayMap[now.getDay()] + `\xa0\xa0\xa0\xa0农历 ${calendarCn}`
    },
  },
  mounted() {
    this.utcMillis = Date.now()
    this.timerId = setInterval(this.updateTime, 1000)
  },
  unmounted() {
    clearInterval(this.timerId)
  },
}
</script>

<style scoped>
.top {
  width: 100%;
  height: 40px;
  display: flex;
}

.left {
  flex: 1.5;
  background: url(@/assets/images/dashboard/header-left-bg.png) no-repeat;
  background-size: cover;
  height: 50px;
  display: flex;
  align-items: center;
  margin-top: -1px;
}

.center {
  flex: 2;
  margin: 0 1.5px;
}

.title {
  width: 100%;
  height: 74px;
  background: url(@/assets/images/dashboard/header-center-bg.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  line-height: 74px;
  color: #29fcff;
  font-size: 1.5vmax;
  user-select: none;
}

.right {
  flex: 1.5;
  background: url(@/assets/images/dashboard/header-left-bg.png) no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  height: 50px;
  margin-top: -1px;
}

.rightBtn {
  width: 100px;
  height: 40px;
  display: block;
  background: url(@/assets/images/dashboard/header-btn-bg-right.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  line-height: 40px;
  color: #29fcff;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
}

.timeColor {
  color: #29fcff;
  font-size: 1vmax;
  user-select: none;
}
</style>
