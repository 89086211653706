<template>
  <CModal size="xl" alignment="center" backdrop="static" :keyboard="false" :visible="showModal">
    <CModalHeader :close-button="false">
      <CModalTitle> {{ title }} </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <CAgGrid
        ref="agGrid"
        :edit="false"
        :colDefs="deviceColDefs"
        :showColumn="false"
        :excelExport="false"
        :enablePrint="false"
        :loadURL="baseURL"
        :serverPageSize="10000"
        :defaultDateRange="1"
        @modifyClick="onDoubleClick"
        :config="config"
        :store="store"
      />
    </CModalBody>
    <AlertModalFooter :visible="alertVisible" :message="alertMessage" @cancel="onBtnCancel" @confirm="onBtnOk" />
  </CModal>
</template>

<script>
import { AlertModalFooter, CAgGrid } from 'ui-common'
import config from '@/config.js'
import store from '@/store'

export default {
  name: 'DeviceQueryModal',
  components: {
    AlertModalFooter,
    CAgGrid,
  },
  props: {
    visible: {
      type: Boolean,
      default: true,
      required: false,
    },
    deviceType: {
      type: String,
      default: '',
      required: false,
    },
  },
  emits: ['cancel', 'confirm'],
  data() {
    const params = this.initParams()

    return {
      config,
      store,
      baseURL: config.baseURL + params.uri,
      deviceColDefs: params.colDef,
      alertVisible: false,
      alertMessage: null,
      showModal: this.visible,
      title: params.title,
    }
  },
  watch: {
    visible(newValue) {
      this.showModal = newValue
    },
    deviceType(newValue, oldValue) {
      if (newValue === oldValue) {
        return
      }

      const params = this.initParams()
      this.baseURL = config.baseURL + params.uri
      this.deviceColDefs = params.colDef
      this.title = params.title
    },
  },
  methods: {
    hideModal() {
      this.showModal = false
      this.alertVisible = false
    },

    initParams() {
      let colDef
      let title
      let uri
      switch (this.deviceType) {
        case 'station':
          title = '分站'
          uri = '/detail/stations'
          colDef = [
            { headerName: '分站', field: 'id', filterType: 'number' },
            { headerName: '类型', field: 'type' },
            { headerName: '所在区域', field: 'areaName' },
            { headerName: '位置', field: 'location' },
          ]
          break

        case 'reader':
          title = '读卡器'
          uri = '/detail/readers'
          colDef = [
            { headerName: '读卡器', field: 'id', filterType: 'number' },
            { headerName: '分站', field: 'stationId', filterType: 'number' },
            { headerName: '类型', field: 'type' },
            { headerName: '所在区域', field: 'areaName' },
            { headerName: '位置', field: 'location' },
          ]
          break

        case 'power':
          title = '电源'
          uri = '/config/table/power'
          colDef = [
            { headerName: '编号', field: 'id', filterType: 'number' },
            {
              headerName: '分站',
              field: 'stationId',
              filterType: 'number',
              valueFormatter: (params) => (params.data.stationId === 0 ? '' : params.data.stationId),
            },
            { headerName: '位置', field: 'location' },
          ]

          break

        default:
          console.log(`unknown device type: ${this.deviceType}`)
          break
      }

      return {
        colDef,
        title,
        uri,
      }
    },

    onDoubleClick(gridApi) {
      const selectedRows = gridApi.getSelectedRows()
      if (selectedRows.length > 0) {
        this.hideModal()
        this.$emit('confirm', { id: selectedRows[0]['id'] })
      }
    },

    onBtnOk() {
      const gridApi = this.$refs.agGrid.gridApi
      const selectedRows = gridApi.getSelectedRows()
      if (selectedRows.length === 0) {
        this.alertMessage = `请选择${this.title}`
        this.alertVisible = true
      } else {
        this.onDoubleClick(gridApi)
      }
    },

    onBtnCancel() {
      this.hideModal()
      this.$emit('cancel')
    },
  },
}
</script>
