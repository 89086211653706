<template>
  <CDropdown variant="nav-item" dark>
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownItem href="/system-config/personal">
        <CIcon icon="cil-settings" />
        个人设置
      </CDropdownItem>
      <CDropdownItem href="#" @click="onLogOut"> <CIcon icon="cil-lock-locked" /> 退出系统 </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>

  <ChooseModal message="确定退出系统吗?" :visible="showLogoutModel" @cancel="onBtnCancel" @confirm="onBtnOk" />
</template>

<script>
import avatar from '@/assets/images/avatars/avatar.svg'
import config from '@/config'
import { httpGetBlob } from '@/common/fetch'
import { ChooseModal } from '@/components/index'

export default {
  name: 'AppHeaderDropdownAccnt',
  components: {
    ChooseModal,
  },
  data() {
    return {
      avatar: null,
      showLogoutModel: false,
    }
  },
  mounted() {
    httpGetBlob(config.baseURL + '/config/table/users/avatar')
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader()
        reader.onload = (event) => {
          if (blob.size > 0) {
            this.avatar = event.target.result
          } else {
            this.avatar = avatar
          }
        }
        reader.readAsDataURL(blob)
      })
      .catch(() => (this.avatar = avatar))
  },
  methods: {
    onLogOut() {
      this.showLogoutModel = true
    },
    onBtnOk() {
      this.$store.commit('removeToken')
      this.$router.push('/pages/login')
    },

    onBtnCancel() {
      this.showLogoutModel = false
    },
  },
}
</script>
