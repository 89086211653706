import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import { Client } from '@stomp/stompjs'
import { system } from 'ui-common'
import config from '@/config'

config.systemType = system.RTLS_PERSON

function rtlsWebsocketClient() {
  let client = new Client({
    brokerURL: '',
    reconnectDelay: 5000,
    heartbeatIncoming: 4000,
    heartbeatOutgoing: 4000,
  })

  const buildBrokerURL = () => {
    return config.baseWS + '/rtls?Authorization=Bearer ' + sessionStorage.getItem('token')
  }

  let isActive = false

  client.onConnect = () => {
    isActive = true
    for (let [destination, token] of subscribers) {
      token.subscription = client.subscribe(destination, token.callback, token.headers)
    }
  }

  client.onDisconnect = () => {
    isActive = false
  }

  client.beforeConnect = () => {
    client.brokerURL = buildBrokerURL()
  }

  const activate = () => {
    client.activate()
  }

  const deactivate = () => {
    client.deactivate()
  }

  const subscribers = new Map()

  const subscribe = (destination, callback, headers = {}) => {
    let subscription = null
    if (isActive) {
      subscription = client.subscribe(destination, callback, headers)
    } else {
      activate()
    }
    subscribers.set(destination, { callback, headers, subscription })
  }

  const unsubscribe = (destination) => {
    const token = subscribers.get(destination)
    if (!token) return

    subscribers.delete(destination)
    if (isActive) {
      token.subscription?.unsubscribe()
    } else {
      activate()
    }
  }

  return {
    activate,
    deactivate,
    subscribe,
    unsubscribe,
  }
}

const app = createApp(App)

app.use(store)
app.use(router)
app.use(CoreuiVue)
app.provide('webSocketClient', rtlsWebsocketClient())
app.provide('icons', icons)
app.component('CIcon', CIcon)

app.mount('#app')
