/*
 * Copyright (C) 2022 Cheng Du Fu Hua Yu Qi Communication Co, Ltd.
 *
 * @author zhang jian ming
 * @date 2022/5/24
 */

import { rtlsRequest } from '@/common/rtlsRequest'

export async function gridLoadData(gridApi, url) {
  return httpGet(url)
    .then((resp) => resp.json())
    .then((data) => gridApi.setRowData(data))
}

export async function httpRequest(method, url, body, timeout, config) {
  const methods = ['POST', 'PUT', 'DELETE']
  if (!methods.includes(method)) {
    throw new Error('Invalid HTTP method')
  }

  return rtlsRequest(method, url, body, config, timeout)
}

export async function httpGet(url, timeout, config) {
  return rtlsRequest('GET', url, null, config, timeout)
}

export async function httpGetBlob(url, timeout) {
  return rtlsRequest(
    'GET',
    url,
    null,
    {
      headers: {
        Accept: 'image/jpeg',
        'Content-Type': 'image/jpeg',
      },
    },
    timeout,
  )
}
