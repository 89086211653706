<template>
  <CFooter class="bg-footer" v-if="sidebarVisible">
    <div></div>
    <div>
      <span class="text-info"> {{ copyright }} </span>
    </div>
    <div></div>
  </CFooter>
</template>

<script>
import config from '@/config'
import store from '@/store'

export default {
  name: 'AppFooter',
  data() {
    return {
      copyright: config.copyright,
    }
  },
  computed: {
    sidebarVisible() {
      return store.state.sidebarVisible
    },
  },
}
</script>
