import { defineComponent, h, onMounted, ref, resolveComponent } from 'vue'
import { RouterLink, useRoute } from 'vue-router'

import { CBadge, CSidebarNav, CNavItem, CNavGroup, CNavTitle } from '@coreui/vue'

import nav from '@/_nav.js'

const navConfig = []
const navConfigStore = JSON.parse(sessionStorage.getItem('navConfig'))
for (let key in navConfigStore) {
  if (!navConfigStore[key]) navConfig.push(key)
}

function filterNav(data, config) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].class && config.includes(data[i].class)) {
      data.splice(i, 1)
      i--
      continue
    }
    if (data[i].items && data[i].items.length > 0) {
      data[i].items = data[i].items.filter((item2) => !config.includes(item2.class))
    }
  }
}

filterNav(nav, navConfig)

const normalizePath = (path) =>
  decodeURI(path)
    .replace(/#.*$/, '')
    .replace(/(index)?\.(html)$/, '')

const isActiveLink = (route, link) => {
  if (link === undefined) {
    return false
  }

  if (route.hash === link) {
    return true
  }

  const currentPath = normalizePath(route.path)
  const targetPath = normalizePath(link)

  return currentPath === targetPath
}

const isActiveItem = (route, item) => {
  if (isActiveLink(route, item.to)) {
    return true
  }

  if (item.items) {
    return item.items.some((child) => isActiveItem(route, child))
  }

  return false
}

const AppSidebarNav = defineComponent({
  name: 'AppSidebarNav',
  components: {
    CNavItem,
    CNavGroup,
    CNavTitle,
  },
  setup() {
    const route = useRoute()
    const firstRender = ref(true)

    onMounted(() => {
      firstRender.value = false
    })

    const renderItem = (item) => {
      if (item.items) {
        return h(
          CNavGroup,
          {
            ...(firstRender.value && {
              visible: item.items.some((child) => isActiveItem(route, child)),
            }),
          },
          {
            togglerContent: () => [
              h(resolveComponent('CIcon'), {
                customClassName: 'nav-icon',
                name: item.icon,
              }),
              item.name,
            ],
            default: () => item.items.map((child) => renderItem(child)),
          },
        )
      }

      return item.to
        ? h(
            RouterLink,
            {
              to: item.to,
              custom: true,
            },
            {
              default: (props) =>
                h(
                  resolveComponent(item.component),
                  {
                    active: props.isActive,
                  },
                  {
                    default: () =>
                      h(
                        RouterLink,
                        {
                          to: props.href,
                          class: 'nav-link',
                          onClick: () => props.navigate(),
                        },
                        {
                          default: () => [
                            item.icon &&
                              h(resolveComponent('CIcon'), {
                                customClassName: 'nav-icon',
                                name: item.icon,
                              }),
                            item.name,
                            item.badge &&
                              h(
                                CBadge,
                                {
                                  class: 'ms-auto',
                                  color: item.badge.color,
                                },
                                {
                                  default: () => item.badge.text,
                                },
                              ),
                          ],
                        },
                      ),
                  },
                ),
            },
          )
        : h(
            resolveComponent(item.component),
            {},
            {
              default: () => item.name,
            },
          )
    }

    return () =>
      h(
        CSidebarNav,
        {},
        {
          default: () => nav.map((item) => renderItem(item)),
        },
      )
  },
})

export { AppSidebarNav }
