/*
 * Copyright (C) 2022 Cheng Du Fu Hua Yu Qi Communication Co, Ltd.
 *
 * @author zhang jian ming
 * @date 2022/6/8
 */

export default {
  HTTP_REQUEST_MSG: '操作失败请重试',
}
