export default [
  {
    component: 'CNavGroup',
    name: '基础设置',
    to: '/basic-config',
    icon: 'cil-settings',
    items: [
      {
        component: 'CNavItem',
        name: '区域设置',
        to: '/basic-config/area',
      },
      {
        component: 'CNavItem',
        name: '部门设置',
        to: '/basic-config/department',
      },
      {
        component: 'CNavItem',
        name: '工种设置',
        to: '/basic-config/work-type',
      },
      {
        component: 'CNavItem',
        name: '职务设置',
        to: '/basic-config/work-title',
      },
      {
        component: 'CNavItem',
        name: '员工管理',
        to: '/basic-config/employee',
      },
      {
        component: 'CNavItem',
        name: '带班领导',
        to: '/basic-config/schedule-leader',
      },
      {
        component: 'CNavItem',
        name: '区域关联',
        to: '/basic-config/area-bind',
      },
      {
        component: 'CNavItem',
        name: '巡检设置',
        to: '/basic-config/work-path',
      },
      {
        component: 'CNavItem',
        name: '超员设置',
        to: '/basic-config/over-config',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: '车辆管理',
    to: '/vehicle-config',
    icon: 'cil-settings',
    class: 'vehicle',
    items: [
      {
        component: 'CNavItem',
        name: '车辆管理',
        to: '/vehicle-config/vehicle',
      },
      {
        component: 'CNavItem',
        name: '车辆类型',
        to: '/vehicle-config/types',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: '实时查询',
    to: '/realtime-query',
    icon: 'cil-monitor',
    items: [
      {
        component: 'CNavItem',
        name: '人员位置',
        to: '/realtime-query/employee-location',
      },
      {
        component: 'CNavItem',
        name: '车辆位置',
        class: 'vehicle',
        to: '/realtime-query/vehicle-location',
      },
      {
        component: 'CNavItem',
        name: '区域人数',
        to: '/realtime-query/area-card-stats',
      },
      {
        component: 'CNavItem',
        name: '手环',
        to: '/realtime-query/band',
      },
      {
        component: 'CNavItem',
        name: '超时告警',
        to: '/realtime-query/alarm/overtime',
      },
      {
        component: 'CNavItem',
        name: '未识别卡',
        to: '/realtime-query/alarm/unknown-cards',
      },
      {
        component: 'CNavItem',
        name: '井下超员告警',
        to: '/realtime-query/alarm/card-over-limit',
      },
      {
        component: 'CNavItem',
        name: '井下超员预警',
        to: '/realtime-query/alarm/early-warn-card-limit',
      },
      {
        component: 'CNavItem',
        name: '区域超员告警',
        to: '/realtime-query/alarm/area-card-over-limit',
      },
      {
        component: 'CNavItem',
        name: '区域超员预警',
        to: '/realtime-query/alarm/area-early-warn-limit',
      },
      {
        component: 'CNavItem',
        name: '受限区域告警',
        to: '/realtime-query/alarm/area-restrict',
      },
      {
        component: 'CNavItem',
        name: '危险区域告警',
        to: '/realtime-query/alarm/area-dangerous',
      },
      {
        component: 'CNavItem',
        name: '未过闸机告警',
        to: '/realtime-query/alarm/gate',
      },
      {
        component: 'CNavItem',
        name: '低电压告警',
        to: '/realtime-query/alarm/low-power-card',
      },
      {
        component: 'CNavItem',
        name: '巡检告警',
        to: '/realtime-query/alarm/work-path',
      },
      {
        component: 'CNavItem',
        name: '人员求救',
        to: '/realtime-query/alarm/card-alert',
      },
      {
        component: 'CNavItem',
        name: '设备告警',
        to: '/realtime-query/alarm/devices',
      },
      {
        component: 'CNavItem',
        name: '电子围栏',
        to: '/realtime-query/alarm/fences',
      },
      {
        component: 'CNavItem',
        name: '逗留超时',
        to: '/realtime-query/alarm/stay-overtime',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: '历史查询',
    to: '/history-query',
    icon: 'cil-history',
    items: [
      {
        component: 'CNavItem',
        name: '人员轨迹',
        to: '/history-query/card-track',
      },
      {
        component: 'CNavItem',
        name: '人员过站',
        to: '/history-query/pass-by-reader',
      },
      {
        component: 'CNavItem',
        name: '每日下井统计',
        to: '/history-query/card-daily-stats',
      },
      {
        component: 'CNavItem',
        name: '车辆轨迹',
        class: 'vehicle',
        to: '/history-query/vehicle-card-track',
      },
      {
        component: 'CNavItem',
        name: '手环',
        to: '/history-query/band',
      },
      {
        component: 'CNavItem',
        name: '下井人数',
        to: '/history-query/card-stats',
      },
      {
        component: 'CNavItem',
        name: '区域人数',
        to: '/history-query/area-card-stats',
      },
      {
        component: 'CNavItem',
        name: '超时告警',
        to: '/history-query/alarm/work-overtime',
      },
      {
        component: 'CNavItem',
        name: '井下超员告警',
        to: '/history-query/alarm/card-over-limit',
      },
      {
        component: 'CNavItem',
        name: '井下超员预警',
        to: '/history-query/alarm/early-warn-card-limit',
      },
      {
        component: 'CNavItem',
        name: '区域超员告警',
        to: '/history-query/alarm/area-card-over-limit',
      },
      {
        component: 'CNavItem',
        name: '区域超员预警',
        to: '/history-query/alarm/area-early-warn-limit',
      },
      {
        component: 'CNavItem',
        name: '未过闸机告警',
        to: '/history-query/alarm/gate',
      },
      {
        component: 'CNavItem',
        name: '低电压告警',
        to: '/history-query/alarm/card-low-power',
      },
      {
        component: 'CNavItem',
        name: '巡检告警',
        to: '/history-query/alarm/work-path',
      },
      {
        component: 'CNavItem',
        name: '人员求救',
        to: '/history-query/alarm/card-alert',
      },
      {
        component: 'CNavItem',
        name: '设备告警',
        to: '/history-query/alarm/devices',
      },
      {
        component: 'CNavItem',
        name: '电子围栏',
        to: '/history-query/alarm/fences',
      },
      {
        component: 'CNavItem',
        name: '逗留超时',
        to: '/history-query/alarm/stay-overtime',
      },
      {
        component: 'CNavItem',
        name: '绑定记录',
        to: '/history-query/card-binding',
      },
      {
        component: 'CNavItem',
        name: '出入记录',
        to: '/history-query/card-in-out-stats',
      },
      {
        component: 'CNavItem',
        name: '人员位置',
        to: '/history-query/card-position',
      },
      {
        component: 'CNavItem',
        name: '操作日志',
        to: '/history-query/operate-log',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: '告警设置',
    to: '/alarm-config',
    icon: 'cil-warning',
    items: [
      {
        component: 'CNavItem',
        name: '分站告警',
        to: '/alarm-config/station',
      },
      {
        component: 'CNavItem',
        name: '读卡器告警',
        to: '/alarm-config/reader',
      },
      {
        component: 'CNavItem',
        name: '标识卡告警',
        to: '/alarm-config/tag',
      },
      {
        component: 'CNavItem',
        name: '区域告警',
        to: '/alarm-config/area',
      },
      {
        component: 'CNavItem',
        name: '一键告警',
        to: '/alarm-config/one-key',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: '设备管理',
    to: '/device-config',
    icon: 'cil-devices',
    items: [
      {
        component: 'CNavItem',
        name: '分站设置',
        to: '/device-config/station',
      },
      {
        component: 'CNavItem',
        name: '读卡器设置',
        to: '/device-config/reader',
      },
      {
        component: 'CNavItem',
        name: '标识卡设置',
        to: '/device-config/tag',
      },
      {
        component: 'CNavItem',
        name: '车辆卡设置',
        class: 'vehicle',
        to: '/device-config/vehicle-tag',
      },
      {
        component: 'CNavItem',
        name: '电源管理',
        to: '/device-config/power',
      },
      {
        component: 'CNavItem',
        name: '闸机设置',
        to: '/device-config/gate',
      },
      {
        component: 'CNavItem',
        name: 'LED屏设置',
        to: '/device-config/led',
      },
      {
        component: 'CNavItem',
        name: '语音播报',
        to: '/device-config/tts',
        class: 'TTS',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: '考勤管理',
    to: '/attendance-config',
    icon: 'cil-calendar',
    items: [
      {
        component: 'CNavItem',
        name: '班次',
        to: '/attendance-config/schedule',
      },
      {
        component: 'CNavItem',
        name: '日考勤',
        to: '/attendance-config/daily',
      },
      {
        component: 'CNavItem',
        name: '月考勤',
        to: '/attendance-config/month',
      },
      {
        component: 'CNavItem',
        name: '考勤统计',
        to: '/attendance-config/stats',
      },
      {
        component: 'CNavItem',
        name: '部门统计',
        to: '/attendance-config/department',
      },
      {
        component: 'CNavItem',
        name: '异常考勤',
        to: '/attendance-config/abnormal',
      },
      {
        component: 'CNavItem',
        name: '异常出勤',
        to: '/attendance-config/abnormal-attendance',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: '地图管理',
    to: '/map',
    icon: 'cil-map',
    items: [
      {
        component: 'CNavItem',
        name: '地图',
        to: '/map/map',
      },
      {
        component: 'CNavItem',
        name: '导入',
        to: '/map/import',
      },
      {
        component: 'CNavItem',
        name: '参数',
        to: '/map/config',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: '系统设置',
    to: '/system-config',
    icon: 'cil-apps-settings',
    items: [
      {
        component: 'CNavItem',
        name: '系统自诊断',
        to: '/system-config/diagnosis',
      },
      {
        component: 'CNavItem',
        name: '个人信息',
        to: '/system-config/personal',
      },
      {
        component: 'CNavItem',
        name: '用户管理',
        to: '/system-config/user',
      },
      {
        component: 'CNavItem',
        name: '用户组管理',
        to: '/system-config/group',
      },
      {
        component: 'CNavItem',
        name: '矿名设置',
        to: '/system-config/mine',
      },
      {
        component: 'CNavItem',
        name: '参数设置',
        to: '/system-config/system',
      },
      {
        component: 'CNavItem',
        name: '关于',
        to: '/system-config/about',
      },
    ],
  },
]
