import { createStore } from 'vuex'
import { selectModule } from './selectData/module'
import { httpGet } from '@/common/fetch.js'
import config from '@/config'

export default createStore({
  state: {
    showLoading: false,
    sidebarVisible: false,
    isAdminUser: sessionStorage.getItem('adminUser') === 'true',
    gridRefreshInterval: 10000,
    token: '',
    username: sessionStorage.getItem('username') || '',
    mineName: '',
  },
  mutations: {
    setNavConfig(state, val) {
      sessionStorage.setItem('navConfig', JSON.stringify(val))
    },

    visibleLoading(state, val) {
      state.showLoading = val
    },

    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },

    updateMineName(state, mineName) {
      state.mineName = mineName
    },

    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },

    updateGridRefreshInterval(state, interval) {
      if (interval > 0) state.gridRefreshInterval = interval
    },

    setAdminUser(state, adminUser) {
      state.isAdminUser = adminUser
      sessionStorage.setItem('adminUser', adminUser)
    },
    setToken: (state, token) => {
      state.token = token
      sessionStorage.setItem('token', token)
    },
    setUsername: (state, username) => {
      state.username = username
      sessionStorage.setItem('username', username)
    },
    removeToken: (state) => {
      sessionStorage.setItem('token', '')
      sessionStorage.setItem('username', '')
      sessionStorage.setItem('adminUser', 'false')
      state.token = ''
    },
  },
  actions: {
    getNavConfig({ commit }) {
      const url = config.baseURL + '/system-info/enable-modules'
      httpGet(url)
        .then((response) => response.json())
        .then((data) => {
          commit('setNavConfig', data)
        })
    },
  },
  modules: { selectModule },
})
