import router from '@/router'
import { interceptors, rtlsFetch } from '@/common/rtlsFetch'

let configHeader = {}
let isPending = false

interceptors.request.use((config) => {
  configHeader = Object.assign(
    {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
    },
    config,
  )

  const token = sessionStorage.getItem('token')

  if (token) {
    configHeader.headers['Authorization'] = `Bearer ${token}`
  }

  return configHeader
})

interceptors.response.use(async (response) => {
  if (isPending) {
    return response
  }

  if (response.ok) {
    return response
  } else if (response.status === 401 || response.status === 403) {
    await router.replace({
      path: '/pages/login',
    })
  }

  return Promise.reject(response)
})

export function rtlsRequest(method, path, body, config, timeout) {
  const myInit = {
    method,
    ...config,
  }

  if (method === 'GET') {
    return rtlsFetch(path, { ...config }, timeout)
  }

  if (body) {
    myInit.body = config?.headers?.['Content-Type']?.indexOf('json') === -1 ? body : JSON.stringify(body)
  }

  return rtlsFetch(path, myInit, timeout)
}
