<template>
  <CSidebar
    position="fixed"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand>
      <CIcon :icon="cilWalk" size="xxl" style="vertical-align: middle; color: #a0efab" />
      <span class="text-header fs-4">
        {{ systemName }}
      </span>
    </CSidebarBrand>
    <AppSidebarNav />
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { cilWalk } from '@coreui/icons'
import { AppSidebarNav } from './AppSidebarNav'
import config from '@/config'
import store from '@/store'

export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    return {
      cilWalk,
      sidebarVisible: computed(() => store.state.sidebarVisible),
      systemName: config.systemName,
    }
  },
}
</script>
