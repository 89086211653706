<template>
  <CModal alignment="top" backdrop="static" :keyboard="false" :visible="visible">
    <CModalBody>
      <CForm id="form" class="row needs-validation" novalidate>
        <CRow>
          <CFormLabel for="switch" class="col-sm-3 col-form-label"> 清除告警 </CFormLabel>
          <div class="col-sm-9 mt-2">
            <CFormSwitch id="switch" label="是" v-model="isClear" />
          </div>
        </CRow>
        <CRow>
          <CFormTextarea
            style="resize: none; margin-left: 0.75rem"
            maxlength="200"
            label="处理措施"
            rows="5"
            :model-value="modelValue"
            feedback-invalid="不能为空"
            @input="$emit('update:modelValue', $event.target.value)"
          />
        </CRow>
      </CForm>
    </CModalBody>
    <AlertModalFooter :visible="alert" message="提交失败，请重试" @cancel="onCancel" @confirm="onConfirm" />
  </CModal>
</template>

<script>
import AlertModalFooter from './AlertModalFooter'

export default {
  name: 'CAlarmHandleModal',
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: false,
    },
    alert: {
      type: Boolean,
      default: false,
      required: false,
    },
    modelValue: {
      type: String,
      default: '',
      required: false,
    },
  },
  components: {
    AlertModalFooter,
  },
  emits: ['submit', 'cancel', 'update:modelValue'],
  data() {
    return {
      isClear: false,
    }
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    onConfirm() {
      this.$emit('submit', { clear: this.isClear, message: this.modelValue })
    },
  },
}
</script>
