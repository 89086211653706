import config from '@/config'

let interceptorsReq = []
let interceptorsReqError = []
let interceptorsRes = []
let interceptorsResError = []

const OriginFetch = window.fetch

function fetchData(input, init, resolve, reject) {
  OriginFetch(input, init)
    .then((response) => {
      interceptorsRes.forEach((item) => {
        response = item(response)
      })
      resolve(response)
    })
    .catch((error) => {
      interceptorsResError.forEach((item) => {
        error = item(error)
      })
      reject(error)
    })
}

let requestPromise = (input, init) => {
  return new Promise((resolve, reject) => {
    if (window.isRefreshing && !input.includes('/auth/refresh')) {
      window.requestQueue.push({ input, init, resolve, reject })
    } else {
      while (window.requestQueue.length > 0) {
        const { input, init, resolve, reject } = window.requestQueue.shift()
        const token = sessionStorage.getItem('token')
        if (token) init.headers['Authorization'] = `Bearer ${token}`
        fetchData(input, init, resolve, reject)
      }
      fetchData(input, init, resolve, reject)
    }
  })
}

function rtlsFetch(input, init = {}, timeout = config.apiRequestTimeout) {
  interceptorsReq.forEach((item) => {
    init = item(init)
  })

  let controller = new AbortController()
  let signal = controller.signal

  if (!init.signal) {
    init.signal = signal
  }

  if (init.headers['Content-Type'] === 'multipart/form-data') {
    Reflect.deleteProperty(init.headers, 'Content-Type')
  }

  let timeoutPromise = (timeout) => {
    return new Promise((_resolve, reject) => {
      setTimeout(() => {
        reject()
        controller.abort()
      }, timeout)
    })
  }

  return Promise.race([timeoutPromise(timeout), requestPromise(input, init)])
}

const interceptors = {
  request: {
    use(callback, errorCallback) {
      interceptorsReq.push(callback)
      errorCallback && interceptorsReqError.push(errorCallback)
    },
  },
  response: {
    use(callback, errorCallback) {
      interceptorsRes.push(callback)
      errorCallback && interceptorsResError.push(errorCallback)
    },
  },
}

export { rtlsFetch, interceptors }
