/*
 * Copyright (C) 2022 Cheng Du Fu Hua Yu Qi Communication Co, Ltd.
 *
 * @author zhang jian ming
 * @date 2022/6/2
 */

const buildWebsocketURL = () => {
  let url = location.protocol === 'https:' ? 'wss://' : 'ws://'
  url += location.host.split(':')[0]
  if (location.port) {
    url += ':' + location.port
  }
  return url + '/api/v1'
}

const GEOSERVER_URL = '/geoserver'

export default {
  /**
   * API base URL
   *
   * @Attetion
   * MUST REPLACE WITH CORRECT URL
   */
  baseURL: '/api/v1',
  baseWS: buildWebsocketURL(),
  gisServerURL: GEOSERVER_URL,
  wmsURL: GEOSERVER_URL + '/mine-layer/wms',
  wmtsURL: GEOSERVER_URL + '/gwc/service/wmts',
  owsURL: GEOSERVER_URL + '/mine/ows',
  systemName: '人员定位系统',
  systemType: null,
  apiRequestTimeout: 15000,
  apiRequestTimeoutLong: 1000 * 60 * 3,
  gridOperateColumnName: '_&$',
  copyright: '©2024 宇祺智能装备有限公司',
}
