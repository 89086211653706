<template>
  <CFormInput
    id="employee-name-input"
    type="text"
    maxlength="50"
    v-model="name"
    @input="onEmployeeNameInput($event)"
    :invalid="employeeInvalid"
    :placeholder="placeholder"
    :disabled="disabled"
  />
  <CButton color="primary" variant="ghost" size="sm" :disabled="disabled" @click="onEmployeeSelect">选择...</CButton>
  <CardQueryModal
    :cardType="cardType"
    :query="query"
    :hideCardIdCol="unbindCard"
    :visible="showEmployeeModal"
    @cancel="onEmployeeQueryCancel"
    @confirm="onEmployeeQueryConfirm"
  />
</template>

<script>
import config from '@/config.js'
import { httpGet } from '@/common/fetch.js'

import CardQueryModal from './CardQueryModal.vue'

export default {
  name: 'EmployeeInput',
  props: {
    id: {
      type: String,
      default: undefined,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    employeeId: {
      type: String,
      default: undefined,
      required: false,
    },
    employeeName: {
      type: String,
      default: undefined,
      required: false,
    },
    unbindCard: {
      type: Boolean,
      default: false,
      required: false,
    },
    cardType: {
      type: String,
      default: '',
      required: false,
    },
    cardId: {
      type: String,
      default: '',
      required: false,
    },
  },
  components: {
    CardQueryModal,
  },
  emits: ['input', 'update:employeeName', 'update:employeeId', 'update:cardId'],
  data() {
    return {
      showEmployeeModal: false,
      name: this.employeeName,
      employeeInvalid: false,
      placeholder: '可选填写',
      debounceTimerId: null,
      query: this.unbindCard ? 'not-binding=true' : '',
    }
  },
  methods: {
    onEmployeeNameInput(event) {
      this.name = event.target.value.trim()
      if (this.name.length === 0) {
        this.$emit('update:employeeName', this.name)
        this.$emit('update:employeeId', '')
        this.$emit('update:cardId', 0)
        this.$emit('input', { name: this.name, id: '', cardId: '', employeeId: '' })
        return
      }

      if (this.debounceTimerId) {
        clearTimeout(this.debounceTimerId)
      }

      const loadEmployees = () => {
        if (this.name.length === 0) return

        let url =
          this.cardType === 'vehicle'
            ? config.baseURL + '/config/table/vehicles/detail?'
            : config.baseURL + '/detail/employees?'
        url += 'not-binding=' + this.unbindCard + '&contains-name='

        httpGet(url + this.name, 1000)
          .then((response) => response.json())
          .then((data) => {
            this.employeeInvalid = data.length < 1
            if (this.employeeInvalid) {
              this.placeholder = this.cardType === 'vehicle' ? '此车辆不存在' : '此人员不存在'
              return
            }

            if (data.length > 1) {
              this.showEmployeeModal = true
            } else {
              if (this.cardType === 'vehicle') {
                this.employeeUpdate({ employeeName: data[0].id, id: data[0].id, cardId: 0 })
              } else {
                this.employeeUpdate(data[0])
              }
            }
          })
          .catch(() => {})
      }

      this.debounceTimerId = setTimeout(loadEmployees, 600)
    },

    onEmployeeSelect() {
      this.placeholder = '可选填写'
      this.employeeInvalid = false
      this.showEmployeeModal = true
    },

    employeeUpdate(employee) {
      this.name = employee.employeeName
      this.$emit('update:employeeName', this.name)
      this.$emit('update:employeeId', employee.id)
      this.$emit('update:cardId', employee.cardId)
      this.$emit('input', { name: this.name, id: employee.id, cardId: employee.cardId, employeeId: '' })

      this.showEmployeeModal = false
      this.employeeInvalid = false
    },

    onEmployeeQueryCancel() {
      this.showEmployeeModal = false
    },

    onEmployeeQueryConfirm(params) {
      this.name = params.name
      this.$emit('update:employeeName', this.name)
      this.$emit('update:employeeId', params.id)
      this.$emit('update:cardId', params.cardId)
      this.$emit('input', {
        name: this.name,
        id: params.id.toString(),
        cardId: params.cardId,
        employeeId: params.id,
      })
      this.showEmployeeModal = false
    },
  },
}
</script>

<style>
#employee-name-input {
  display: inline-block;
  margin-right: 2px;
  width: 80%;
}
</style>
