import {
  cilActionRedo,
  cilActionUndo,
  cilAppsSettings,
  cilBell,
  cilBorderClear,
  cilCalendar,
  cilCenterFocus,
  cilCircle,
  cilDevices,
  cilFile,
  cilFingerprint,
  cilHistory,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMediaPause,
  cilMediaPlay,
  cilMediaSkipBackward,
  cilMediaSkipForward,
  cilMediaStop,
  cilMinus,
  cilMonitor,
  cilPen,
  cilPenNib,
  cilPlus,
  cilPrint,
  cilReload,
  cilRectangle,
  cilRunning,
  cilSave,
  cilSearch,
  cilSettings,
  cilSync,
  cilText,
  cilUser,
  cilVerticalAlignCenter,
  cilWarning,
  cilX,
  cilZoomIn,
  cilZoomOut,
  cilPeople,
  cilBorderAll,
  cilDelete,
  cilFilterFrames,
  cilExpandLeft,
  cilBrushAlt,
} from '@coreui/icons'

export const iconsSet = Object.assign(
  {},
  {
    cilActionRedo,
    cilActionUndo,
    cilAppsSettings,
    cilBell,
    cilBorderClear,
    cilCalendar,
    cilCenterFocus,
    cilCircle,
    cilDevices,
    cilFile,
    cilFingerprint,
    cilHistory,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMap,
    cilMediaPause,
    cilMediaPlay,
    cilMediaSkipBackward,
    cilMediaSkipForward,
    cilMediaStop,
    cilMinus,
    cilMonitor,
    cilPen,
    cilPenNib,
    cilPlus,
    cilPrint,
    cilReload,
    cilRectangle,
    cilRunning,
    cilSave,
    cilSearch,
    cilSettings,
    cilSync,
    cilText,
    cilUser,
    cilVerticalAlignCenter,
    cilWarning,
    cilX,
    cilZoomIn,
    cilZoomOut,
    cilPeople,
    cilBorderAll,
    cilDelete,
    cilFilterFrames,
    cilExpandLeft,
    cilBrushAlt,
  },
)
