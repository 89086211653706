<template>
  <div style="min-width: 1000px; overflow: hidden">
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100" :class="bgColor">
      <AppHeader />
      <div class="body flex-grow-1">
        <CContainer class="py-0" fluid>
          <router-view v-slot="{ Component }">
            <transition name="scale" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </CContainer>
        <Loading v-if="showLoading" />
      </div>
      <AppFooter />
    </div>
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue'
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import { Loading } from 'ui-common'
import store from '@/store'
import { mapState } from 'vuex'

export default {
  name: 'DefaultLayout',
  setup() {
    const bgColor = store.state.sidebarVisible ? '' : 'bg-main'
    return {
      bgColor,
    }
  },
  components: {
    CContainer,
    AppHeader,
    AppFooter,
    AppSidebar,
    Loading,
  },
  computed: {
    ...mapState(['showLoading']),
  },
}
</script>

<style scoped>
.scale-enter-active,
.scale-leave-active {
  transition: all 0.5s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>
