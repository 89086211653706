<template>
  <CFormInput
    id="device-input"
    type="number"
    v-model="deviceId"
    @input="onInput($event)"
    :invalid="deviceInvalid"
    :placeholder="placeholder"
    :disabled="disabled"
    :required="required"
  />
  <CButton color="primary" variant="ghost" size="sm" :disabled="disabled" @click="onDeviceSelect">选择...</CButton>
  <DeviceQueryModal
    :deviceType="deviceType"
    :visible="showDeviceModal"
    @cancel="onDeviceQueryCancel"
    @confirm="onDeviceQueryConfirm"
  />
</template>

<script>
import config from '@/config.js'
import { httpGet } from '@/common/fetch.js'

import DeviceQueryModal from './DeviceQueryModal.vue'

export default {
  name: 'DeviceInput',
  props: {
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    id: {
      type: String,
      default: undefined,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    deviceType: {
      type: String,
      default: '',
      required: false,
    },
  },
  components: {
    DeviceQueryModal,
  },
  emits: ['input', 'update:id'],
  data() {
    return {
      showDeviceModal: false,
      deviceId: this.id,
      deviceInvalid: false,
      placeholder: '可选填写',
      debounceTimerId: null,
    }
  },
  methods: {
    onInput(event) {
      const deviceId = event.target.value.trim()
      if (deviceId.length === 0) {
        this.$emit('input', { id: '' })
        return
      }

      const isInvalidId = (deviceId) => {
        const id = +deviceId
        return id < 1 || id > 65535
      }

      if (isInvalidId(deviceId)) {
        this.deviceInvalid = true
        this.placeholder = '编号范围1-65535'
        return
      }

      this.deviceId = deviceId

      if (this.debounceTimerId) {
        clearTimeout(this.debounceTimerId)
      }

      const loadDevices = () => {
        if (this.deviceId.length === 0 || isInvalidId(this.deviceId)) return

        let url
        switch (this.deviceType) {
          case 'station':
            url = config.baseURL + '/config/table/stations/'
            break

          case 'reader':
            url = config.baseURL + '/config/table/stations/'
            break

          case 'power':
            url = config.baseURL + '/config/table/power/'
            break

          default:
            return
        }

        httpGet(url + this.deviceId, 1000)
          .then((response) => response.json())
          .then((data) => {
            this.deviceUpdate({ id: data.id })
          })
          .catch((e) => {
            if (e?.status === 400) {
              this.deviceInvalid = true
              this.placeholder = '不存在'
            }
          })
      }

      this.debounceTimerId = setTimeout(loadDevices, 600)
    },

    onDeviceSelect() {
      this.placeholder = '可选填写'
      this.deviceInvalid = false
      this.showDeviceModal = true
    },

    deviceUpdate(device) {
      this.deviceId = device.id
      this.$emit('update:id', device.id)
      this.$emit('input', { id: device.id })

      this.showDeviceModal = false
      this.deviceInvalid = false
    },

    onDeviceQueryCancel() {
      this.showDeviceModal = false
    },

    onDeviceQueryConfirm(device) {
      this.deviceId = device.id
      this.$emit('update:id', device.id)
      this.$emit('input', { id: device.id })
      this.showDeviceModal = false
    },
  },
}
</script>

<style>
#device-input {
  display: inline-block;
  margin-right: 2px;
  width: 80%;
}
</style>
