/*
 * Copyright (C) 2022 Cheng Du Fu Hua Yu Qi Communication Co, Ltd.
 *
 * @author zhang jian ming
 * @date 2022/5/24
 */

import error from '@/common/error'

export function getTextColor() {
  let root = document.querySelector(':root')
  let color = getComputedStyle(root).getPropertyValue('--rtls-text-color')
  return color
}

export function getBackgroundColor() {
  let root = document.querySelector(':root')
  let color = getComputedStyle(root).getPropertyValue('--rtls-background-color')
  return color
}

export function isValidIPAddress(ipAddr) {
  const regex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
  return regex.test(ipAddr)
}

//format date to YYYY-MM-DD HH:MM:SS
export function dateTimeFormat(date) {
  const toTowDigits = (value) => value.toString().padStart(2, '0')

  return (
    date.getFullYear() +
    '-' +
    toTowDigits(date.getMonth() + 1) +
    '-' +
    toTowDigits(date.getDate()) +
    ' ' +
    toTowDigits(date.getHours()) +
    ':' +
    toTowDigits(date.getMinutes()) +
    ':' +
    toTowDigits(date.getSeconds())
  )
}

export function tagPowerCalc(powerValue) {
  if (powerValue >= 41) return '100%'
  if (powerValue == 0) return ''
  if (powerValue <= 33) return '1%'
  return Math.floor(((powerValue - 33) * 100) / 8) + '%'
}

export function isVehicleCard(cardId) {
  return cardId >= 1 && cardId <= 2000
}

export async function getServerTip(data) {
  if ('status' in data) {
    return await data.json().then((json) => {
      return json.message
    })
  } else {
    return error.HTTP_REQUEST_MSG
  }
}

export function div(numerator, denominator) {
  return [Math.floor(numerator / denominator), numerator % denominator]
}

export function durationFormat(duration) {
  if (duration === 0) return '0秒'

  const [days, remainder1] = div(Math.floor(duration), 24 * 3600)
  const [hours, remainder2] = div(remainder1, 3600)
  const [minutes, seconds] = div(remainder2, 60)

  let str = ''
  if (days > 0) {
    str += `${days}天`
  }

  if (hours > 0) {
    str += `${hours}小时`
  }

  if (minutes > 0) {
    str += `${minutes}分`
  }

  if (seconds > 0) {
    str += `${seconds}秒`
  }

  return str
}

export function setColIndex(params) {
  return params.node ? params.node.rowIndex + 1 : null
}

export function isValidEpoch(time) {
  return time !== '1970-01-01 08:00:00'
}
