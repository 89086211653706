import { httpGet } from '@/common/fetch.js'
import config from '@/config'

export const selectModule = {
  state: {
    defaultAreas: [],
    defaultDepartments: [],
    defaultWorkTypes: [],
  },
  mutations: {
    setDefaultAreas(state, areaArr) {
      state.defaultAreas = [
        {
          label: '全部区域',
          value: '',
        },
        ...areaArr,
      ]
    },
    setDefaultDepartments(state, departmentArr) {
      state.defaultDepartments = [
        {
          label: '所有部门',
          value: '',
        },
        ...departmentArr,
      ]
    },
    setDefaultWorkTypes(state, workTypeArr) {
      state.defaultWorkTypes = [
        {
          label: '全部工种',
          value: '',
        },
        ...workTypeArr,
      ]
    },
  },
  actions: {
    getAreas({ commit }) {
      const areaUrl = config.baseURL + '/config/table/areas'
      httpGet(areaUrl)
        .then((response) => response.json())
        .then((data) => {
          const areaArr = data.map((data) => {
            return {
              label: data.name.substring(0, 6),
              value: data.name,
            }
          })
          commit('setDefaultAreas', areaArr)
        })
    },

    getDepartments({ commit }) {
      const departmentUrl = config.baseURL + '/config/table/departments'
      httpGet(departmentUrl)
        .then((response) => response.json())
        .then((data) => {
          const departmentArr = data.map((data) => {
            return {
              label: data.name.substring(0, 6),
              value: data.name,
            }
          })
          commit('setDefaultDepartments', departmentArr)
        })
    },

    getWorkTypes({ commit }) {
      const workTypeUrl = config.baseURL + '/config/table/work-types'
      httpGet(workTypeUrl)
        .then((response) => response.json())
        .then((data) => {
          const workTypeArr = data.map((data) => {
            return {
              label: data.name.substring(0, 6),
              value: data.name,
            }
          })
          commit('setDefaultWorkTypes', workTypeArr)
        })
    },
  },
}
