<template>
  <CModal size="xl" alignment="center" backdrop="static" :keyboard="false" :visible="showModal">
    <CModalHeader :close-button="false">
      <CModalTitle> {{ title }} </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <CAgGrid
        ref="agGrid"
        :edit="false"
        :colDefs="employeeColDefs"
        :datePicker="enableDatePicker"
        :extraQueryParams="query"
        :enableDateRange="true"
        :showColumn="false"
        :excelExport="false"
        :enablePrint="false"
        :customRowData="tableData"
        @refresh="getTableData"
        :serverPageSize="10000"
        :defaultDateRange="1"
        :maxDateRange="1"
        @modifyClick="onDoubleClick"
        :store="store"
        :config="config"
        :gridRight="5"
      >
        <template v-if="cardType === 'employee'" v-slot:conditionsOnRight>
          <CFormSelect
            :options="defaultWorkTypes"
            id="workType"
            v-model="workType"
            @change="handleChange"
            style="margin-right: 5px"
          />
        </template>
      </CAgGrid>
    </CModalBody>
    <AlertModalFooter :visible="alertVisible" :message="alertMessage" @cancel="onBtnCancel" @confirm="onBtnOk" />
  </CModal>
</template>

<script>
import { AlertModalFooter, CAgGrid } from 'ui-common'
import config from '@/config.js'
import store from '@/store'
import { mapActions, mapState } from 'vuex'
import { httpGet } from '@/common/fetch'

export default {
  name: 'CardQueryModal',
  components: {
    AlertModalFooter,
    CAgGrid,
  },
  props: {
    colDefs: {
      type: Array,
      default: null,
      required: false,
    },
    datePicker: {
      type: Boolean,
      default: false,
      required: false,
    },
    query: {
      type: String,
      default: '',
      required: false,
    },
    visible: {
      type: Boolean,
      default: true,
      required: false,
    },
    cardType: {
      type: String,
      default: '',
      required: false,
    },
    hideCardIdCol: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emits: ['cancel', 'confirm'],
  data() {
    const params = this.initParams()
    return {
      store,
      config,
      baseURL: config.baseURL + params.uri,
      employeeColDefs: this.colDefs || params.colDef,
      alertVisible: false,
      alertMessage: null,
      showModal: this.visible,
      title: params.title,
      enableDatePicker: this.datePicker,
      nameField: params.nameField,
      idField: params.idField,
      cardIdField: params.cardIdField,
      workType: '',
      tableData: [],
      originData: [],
    }
  },

  computed: {
    ...mapState({
      defaultWorkTypes: (state) => state.selectModule.defaultWorkTypes,
    }),
  },

  watch: {
    visible(newValue) {
      this.showModal = newValue
    },
    datePicker(newValue) {
      this.enableDatePicker = newValue
    },
    cardType() {
      const params = this.initParams()
      this.baseURL = config.baseURL + params.uri
      this.employeeColDefs = params.colDef
      this.title = params.title
      this.nameField = params.nameField
      this.idField = params.idField
      this.cardIdField = params.cardIdField
    },
  },
  methods: {
    getTableData() {
      this.$nextTick(() => {
        httpGet(this.baseURL)
          .then((response) => response.json())
          .then((data) => {
            this.tableData = data
            this.originData = data
          })
      })
    },

    hideModal() {
      this.alertVisible = false
    },

    initParams() {
      const isVehicle = this.cardType === 'vehicle'

      let uri
      let title
      let nameHeader
      let idHeader
      let nameField
      let idField
      let cardIdField
      switch (this.cardType) {
        case 'employee':
          uri = '/detail/employees'
          nameHeader = '姓名'
          idHeader = '工号'
          title = '员工选择'
          nameField = 'employeeName'
          idField = 'id'
          cardIdField = 'cardId'
          break

        case 'vehicle':
          uri = '/config/table/vehicles/detail'
          nameHeader = '车牌'
          idHeader = '车牌'
          title = '车辆选择'
          nameField = 'id'
          idField = 'id'
          cardIdField = 'cardId'
          break

        default:
          uri = '/config/table/cards/detail'
          nameHeader = '姓名/车牌号'
          idHeader = '工号/车牌'
          title = '标识卡选择'
          nameField = 'employeeName'
          idField = 'employeeId'
          cardIdField = 'id'
          break
      }

      const employeeColDefs = [
        {
          headerName: '卡号',
          field: cardIdField,
          filterType: 'number',
          valueFormatter: (params) => {
            const cardId = params.data[cardIdField]
            return cardId === 0 ? '' : cardId
          },
          hide: this.hideCardIdCol,
        },
        { headerName: idHeader, field: idField, hide: isVehicle },
        { headerName: nameHeader, field: nameField },
        { headerName: '部门', field: 'departmentName' },
        { headerName: '工种', field: 'workTypeName', hide: isVehicle },
      ]

      return {
        colDef: employeeColDefs,
        uri: uri,
        title: title,
        nameField: nameField,
        idField: idField,
        cardIdField: cardIdField,
      }
    },

    onDoubleClick(gridApi) {
      const selectedRows = gridApi.getSelectedRows()

      if (selectedRows.length > 0) {
        this.alertVisible = false
        const [startTime, endTime] = this.$refs.agGrid.getChooseDate()
        const params = {
          startTime,
          endTime,
          name: selectedRows[0][this.nameField],
          id: selectedRows[0][this.idField],
          cardId: selectedRows[0][this.cardIdField],
          employeeId: selectedRows[0].employeeId,
        }

        this.hideModal()
        this.$emit('confirm', params)
      }
    },

    onBtnOk() {
      const gridApi = this.$refs.agGrid.gridApi
      const selectedRows = gridApi.getSelectedRows()
      if (selectedRows.length === 0) {
        this.alertMessage = '请选择标识卡'
        this.alertVisible = true
      } else {
        this.onDoubleClick(gridApi)
      }
    },

    onBtnCancel() {
      this.hideModal()
      this.$emit('cancel')
    },

    handleChange() {
      this.$nextTick(() => {
        this.tableData = this.workType
          ? this.tableData.filter((item) => item.workTypeName === this.workType)
          : this.originData
      })
    },

    ...mapActions(['getWorkTypes']),
  },

  mounted() {
    this.getWorkTypes()
    this.getTableData()
  },
}
</script>
