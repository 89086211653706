import jwt_decode from 'jwt-decode'

export function isExpireJWT(token, currentTime) {
  return isNeedRefreshJWT(token, 0, currentTime)
}

export function isNeedRefreshJWT(token, time, currentTime) {
  if (!token) return true

  let decodeToken
  try {
    decodeToken = jwt_decode(token)
  } catch (error) {
    return true
  }

  return decodeToken?.exp ? decodeToken.exp - Math.round(currentTime / 1000) <= time : false
}
