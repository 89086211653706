<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
import { isExpireJWT } from '@/common/jwt'
import config from '@/config'
import { httpGet } from '@/common/fetch'

export default {
  data() {
    const time = sessionStorage.getItem('logoutTime')
    return {
      timerId: null,
      logoutTimer: null,
      logoutTime: time ? +time : 0,
      token: null,
      currentTime: null,
    }
  },
  async created() {
    window.isRefreshing = false
    window.requestQueue = []
    document.addEventListener('mousemove', this.onMouseMove)
    this.token = sessionStorage.getItem('token')
    if (!this.token) {
      this.goLoginPage()
      this.logoutTimer = this.addInterval()
      return
    }

    const time = new Date().getTime() / 1000
    if (isExpireJWT(this.token, time)) {
      this.goLoginPage()
      return
    }

    this.logoutTimer = this.addInterval()
  },
  unmounted() {
    clearTimeout(this.timerId)
    clearTimeout(this.logoutTimer)
  },
  methods: {
    async refreshJWT() {
      window.isRefreshing = true
      let token
      await httpGet(config.baseURL + '/auth/refresh')
        .then((response) => response.json())
        .then((data) => {
          window.isRefreshing = false
          if (data.message) return
          token = data.token
          sessionStorage.setItem('token', token)
        })
        .catch(() => {
          window.requestQueue.forEach(({ reject }) => reject('error'))
          window.requestQueue.length = 0
        })
    },

    addInterval() {
      return setInterval(() => {
        this.token = sessionStorage.getItem('token')
        this.refreshJWT()
      }, 1000 * 60 * 10)
    },

    goLoginPage() {
      sessionStorage.clear()
      this.$router.push({ path: '/pages/login' })
      clearTimeout(this.timerId)
    },

    onMouseMove() {
      if (this.logoutTime === 0) return
      clearTimeout(this.timerId)
      this.timerId = setTimeout(() => {
        this.goLoginPage()
      }, this.logoutTime)
    },
  },
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
