<template>
  <CModalFooter>
    <CAlert color="warning" class="d-flex align-items-center" style="height: 40px" :visible="alertVisible">
      {{ alertMessage }}
    </CAlert>
    <CButton color="secondary" @click="onCancelClick">取消</CButton>
    <CButton color="primary" @click="onConfirmClick">确认</CButton>
  </CModalFooter>
</template>

<script>
export default {
  name: 'AlertModalFooter',
  props: {
    message: {
      type: String,
      default: null,
      required: false,
    },
    visible: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emits: ['cancel', 'confirm'],
  data() {
    return {
      alertVisible: this.visible,
      alertMessage: this.message,
    }
  },
  watch: {
    message(newValue) {
      this.alertMessage = newValue
    },
    visible(newValue) {
      this.alertVisible = newValue
    },
  },
  methods: {
    onCancelClick(event) {
      this.$emit('cancel', event)
    },
    onConfirmClick(event) {
      this.$emit('confirm', event)
    },
  },
}
</script>
